import React, { useState, useEffect, useRef } from 'react';
import {
  CloseButton, Title, InfoBox, InfoHeader,
  InfoTitle, InfoPrice, InfoDescription,
  Divider, AddressBox, FormBox, LoadingBox,
  LoadingWrapper, SuccessWrapper, SuccessBox,
  IconInput
} from './CheckoutAtoms';
import InputMask from 'react-input-mask';
import CurrencyInput from '../Components/CurrencyInput';
import { ColorRing } from 'react-loader-spinner'
import Moment from 'moment';
import ConsultaCep from '../Services/BrasilAPI';
import ConsultaCepBiz from '../Services/BiZAPI';
import { createUser } from '../Services/AsaasAPI';
import CreditCard from '../Components/CreditCard';
import { AnalyticEvent } from '../Components/Analytics';

const plans = [
  {
    name: 'Lavrador',
    price: 14.9,
    displayPrice: '14,90',
    description: 'Rodas de conversa promovidas pelo Instituto Dona Mara, 1 caneta exclusiva do Instituto Dona Mara.',
    asaasDescription: 'Associado Lavrador'
  },
  {
    name: 'Semeador',
    price: 49.9,
    displayPrice: '49,90',
    description: 'Rodas de conversa promovidas pelo Instituto Dona Mara, 1 caneta exclusiva do Instituto Dona Mara, 1 caneca exclusiva do Instituto Dona Mara, 1 ingresso anual para as oficinas de aprendizado promovidas pelo Instituto Dona Mara, 1 ingresso anual para as palestras promovidas pelo Instituto Dona Mara.',
    asaasDescription: 'Associado Semeador'
  },
  {
    name: 'Cultivador',
    price: 99.9,
    displayPrice: '99,90',
    description: 'Rodas de conversa promovidas pelo Instituto Dona Mara, 1 caneta exclusiva do Instituto Dona Mara, 1 caneca exclusiva do Instituto Dona Mara, 1 camiseta exclusiva do Instituto Dona Mara, 2 ingressos anuais para as oficinas de aprendizado promovidas pelo Instituto Dona Mara, 2 ingressos anuais para as palestras promovidas pelo Instituto Dona Mara.',
    asaasDescription: 'Associado Cultivador'
  },
  {
    name: 'Custom',
    price: 0,
    displayPrice: '0',
    description: 'Rodas de conversa promovidas pelo Instituto Dona Mara, 1 caneta exclusiva do Instituto Dona Mara, 1 caneca (sustentável) do Instituto Dona Mara, 1 camisa pólo exclusiva do Instituto Dona Mara, 3 ingressos anuais para as oficinas de aprendizado promovidas pelo Instituto Dona Mara, 3 ingressos anuais para as palestras promovidas pelo Instituto Dona Mara, Descontos exclusivos na feira de vinhos da Padaria Real.',
    asaasDescription: 'Associado Inspirador'
  }
];

function Checkout({ plan, onCloseCheckout }) {
  const [address, setAddress] = useState({});
  const [holderAddress, setHolderAddress] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [cardOwnerHolder, setCardOwnerHolder] = useState();
  const [ip, setIP] = useState("");
  const [isloading, setIsLoading] = useState();
  const [showError, setShowError] = useState();
  const [showPriceError, setShowPriceError] = useState();
  const [showSuccess, setShowSuccess] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [customValue, setCustomValue] = useState(0);
  const [userForm, setUserForm] = useState({});
  const [holderForm, setHolderForm] = useState({});
  const [country, setCountry] = useState('Brazil');
  const [description, setDescription] = useState('');
  const [userCep, setUserCep] = useState('');
  const [holderCep, setHolderCep] = useState('');

  const validateForm = () => {
    if (
      userForm.name &&
      userForm.cpf &&
      userForm.email &&
      userForm.mobilePhone &&
      ((country === 'Brazil' && userForm.addressNumber && address.cep) ||
        (country !== 'Brazil' &&
          address.cep &&
          address.street &&
          userForm.addressNumber &&
          address.city &&
          address.state &&
          address.neighborhood)) &&
      (userForm.shirt || plan === 'Lavrador' || plan === 'Semeador') &&
      userForm.creditCard &&
      userForm.creditCard.number &&
      userForm.cardExpires &&
      userForm.cvc &&
      userForm.cardName &&
      (!cardOwnerHolder || (cardOwnerHolder &&
        holderForm.name &&
        holderForm.cpf &&
        holderForm.mobilePhone &&
        holderForm.email &&
        ((country === 'Brazil' && holderForm.addressNumber && holderAddress.cep) ||
          (country !== 'Brazil' &&
            holderAddress.cep &&
            holderAddress.street &&
            holderForm.addressNumber &&
            holderAddress.city &&
            holderAddress.state &&
            holderAddress.neighborhood))
      ))
    ) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  };

  const updateUserForm = (field, value) => {
    setUserForm(prev => ({ ...prev, [field]: value }))
    validateForm();
  };

  const updateHolderForm = (field, value) => {
    setHolderForm(prev => ({ ...prev, [field]: value }));
    validateForm();
  };

  const getIPData = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const res = await response.json();
      setIP(res.ip);
    } catch (error) {
      console.error("Failed to fetch IP address", error);
    }
  };

  useEffect(() => {
    getIPData();
    if (plan !== 'Custom') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (plan) {
      const selected = plans.find(i => i.name === plan);
      setSelectedPlan(selected);
    }
  }, [plan]);

  useEffect(() => {
    validateForm();
  }, [cardOwnerHolder, country, description, address, holderAddress]);

  const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
  }

  const [input1Ref, setInput1Focus] = UseFocus()

  return (
    <div>
      {showError && (
        <div className="alert alert-danger" style={{ margin: '0 10%', position: 'fixed', zIndex: 9999, top: '10px', right: '10px' }} role="alert">
          Ocorreu um erro ao processar sua assinatura, por favor tente novamente mais tarde.
        </div>
      )}
      {showPriceError && (
        <div className="alert alert-danger" style={{ margin: '0 10%', position: 'fixed', zIndex: 9999, top: '10px', right: '10px' }} role="alert">
          A contribuição do Associado Inspirador não pode ser inferior a R$ 99,90.
        </div>
      )}
      <div className={`CheckoutBox ${(isloading || showSuccess) && 'd-none'}`}>
        <CloseButton onClick={() => {
          AnalyticEvent("Checkout");
          onCloseCheckout()
        }}>X</CloseButton>
        <Title>Informações da sua associação</Title>
        <InfoBox>
          <InfoHeader>
            <InfoTitle>Associado {plan === 'Custom' ? 'Inspirador' : plan}</InfoTitle>
            {plan === 'Custom' ? (
              <InfoPrice>
                <b>R$ <CurrencyInput style={{ display: 'inline-block', width: '100px' }} value={customValue} className="form-control" thousandSeparator="." decimalSeparator="," onChange={(e) => {
                  setCustomValue(e);
                  setShowPriceError();
                }}
                />
                </b> por mês
              </InfoPrice>
            ) : (
              <InfoPrice><b>R$ {selectedPlan.displayPrice}</b> por mês</InfoPrice>
            )}
          </InfoHeader>
          <InfoDescription>
            {selectedPlan ? selectedPlan.description : ''}
          </InfoDescription>
        </InfoBox>
        <Divider></Divider>
        <FormBox>
          <h3>Sua localização</h3>
          <form style={{ margin: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Selecione o país</label>
                <select className="form-control" onChange={(e) => {
                  setCountry(e.target.value);
                  validateForm();
                  updateUserForm('mobilePhone', '');
                  updateUserForm('addressNumber', '');
                  updateUserForm('complement', '');
                  setUserCep('');
                  setAddress({});
                  setDescription('');
                  updateHolderForm('mobilePhone', '');
                  updateHolderForm('addressNumber', '');
                  updateHolderForm('complement', '');
                  setHolderCep('');
                  setHolderAddress({});
                }}>
                  <option value="Brazil">Brasil</option>
                  <option value="Other">Outro país</option>
                </select>
              </div>
            </div>
          </form>
          <Divider style={{ marginBottom: '16px' }}></Divider>
          <h3>Suas informações</h3>
          <form style={{ margin: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">CPF</label>
                <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                  updateUserForm('cpf', e.target.value)
                }} maskChar="" >
                  {(inputProps) => <input {...inputProps} ref={input1Ref} />}
                </InputMask>
              </div>
              <div className="form-group col-md-8">
                <label for="namee">Nome completo</label>
                <input type="text" placeholder="Nome completo" className="form-control" id="namee" onChange={(e) => { updateUserForm('name', e.target.value) }} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputCel">Celular / Whatsapp</label>
                {country === 'Brazil' ? (
                  <IconInput>
                    <i className="bi bi-telephone"></i>
                    <InputMask className="form-control" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => { updateUserForm('mobilePhone', e.target.value); }} maskChar="" />
                  </IconInput>
                ) : (
                  <IconInput>
                    <i className="bi bi-telephone"></i>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Número de telefone"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        updateUserForm('mobilePhone', e.target.value);
                      }}
                    />
                  </IconInput>
                )}
              </div>
              <div className="form-group col-md-8">
                <label for="exampleInputEmail1">Email</label>
                <IconInput>
                  <i className="bi bi-envelope"></i>
                  <input type="email" placeholder="eu@email.com" className="form-control" id="exampleInputEmail1" onChange={(e) => { updateUserForm('email', e.target.value) }} />
                </IconInput>
              </div>
            </div>
          </form>
          <Divider style={{ marginBottom: '16px' }}></Divider>
          <h3>Seu kit de boas-vindas</h3>
          <form style={{ padding: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">{country === 'Brazil' ? 'CEP' : 'CEP'}</label>
                <IconInput>
                  <i className="bi bi-geo-fill"></i>
                  <InputMask
                    className="form-control"
                    mask={country === 'Brazil' ? "99999-999" : "99999999"}
                    placeholder={country === 'Brazil' ? "00000-000" : "00000000"}
                    value={userCep}
                    onChange={async (e) => {
                      const cepValue = e.target.value;
                      setUserCep(cepValue);
                      if (country === 'Brazil' && cepValue.length === 9) {
                        let addressInfo = await ConsultaCep(cepValue.replaceAll("-", ""));
                        if (!addressInfo.cep) {
                          addressInfo = await ConsultaCepBiz(cepValue.replaceAll("-", ""), addressInfo);
                        }
                        setAddress(addressInfo);
                      }
                      else {
                        setAddress(prev => ({ ...prev, cep: e.target.value }));
                      }
                    }}
                    maskChar=""
                  />
                </IconInput>
              </div>
              <div className="form-group col-md-8">
                <AddressBox style={{ margin: '20px' }}>
                  {country === 'Brazil' ? (
                    <>
                      <p>{address.street}</p>
                      <p>{address.neighborhood} - {address.city} - {address.state}</p>
                    </>
                  ) : (
                    <div className="row">
                      <div className="form-group col-md-4">
                        <input type="text" placeholder="Logradouro" className="form-control mb-2" onChange={(e) => {setAddress(prev => ({ ...prev, street: e.target.value })); }} />
                      </div>
                      <div className="form-group col-md-2">
                        <input type="text" placeholder="Nº" className="form-control mb-2" onChange={(e) => updateUserForm('addressNumber', e.target.value)} />
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" placeholder="Complemento" className="form-control mb-2" onChange={(e) => updateUserForm('complement', e.target.value)} />
                      </div>
                      <div className="form-group col-md-4">
                        <input type="text" placeholder="Bairro" className="form-control mb-2" onChange={(e) => {setAddress(prev => ({ ...prev, neighborhood: e.target.value })); }} />
                      </div>
                      <div className="form-group col-md-4">
                        <input type="text" placeholder="Cidade" className="form-control mb-2" onChange={(e) => {setAddress(prev => ({ ...prev, city: e.target.value })); }} />
                      </div>
                      <div className="form-group col-md-4">
                        <input type="text" placeholder="Estado" className="form-control mb-2" onChange={(e) => {setAddress(prev => ({ ...prev, state: e.target.value })); }} />
                      </div>
                    </div>
                  )}
                </AddressBox>
              </div>
            </div>
            {country === 'Brazil' && (
              <div className="row">
                <div className="form-group col-md-4">
                  <label>Número</label>
                  <input type="text" placeholder="000" className="form-control" onChange={(e) => updateUserForm('addressNumber', e.target.value)} />
                </div>
                <div className="form-group col-md-8">
                  <label>Complemento</label>
                  <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" onChange={(e) => updateUserForm('complement', e.target.value)} />
                </div>
              </div>
            )}
            {(plan === 'Custom' || plan === 'Cultivador') &&
              <><div className="form-group">
                <label>Qual o tamanho de camiseta que você gostaria?</label>
              </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio1" value="P" />
                  <label className="form-check-label" for="inlineRadio1">P</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio2" value="M" />
                  <label className="form-check-label" for="inlineRadio2">M</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio3" value="G" />
                  <label className="form-check-label" for="inlineRadio3">G</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio4" value="GG" />
                  <label className="form-check-label" for="inlineRadio4">GG</label>
                </div></>
            }
          </form>
          <Divider style={{ marginBottom: '16px' }}></Divider>
          <h3>Seu pagamento</h3>
          <form style={{ padding: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label>Número do cartão de crédito</label>
                <CreditCard
                  onUpdate={(e) => {
                    updateUserForm('creditCard', e);
                    validateForm();
                  }}
                />
              </div>
              <div className="form-group col-md-4">
                <label for="card_name">Nome do titular <em>(como está gravado no cartão)</em></label>
                <input type="text" placeholder="Nome impresso no cartão" className="form-control" id="card_name" onChange={(e) => { updateUserForm('cardName', e.target.value); }} />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cardExpires">Validade</label>
                <InputMask className="form-control" placeholder="MM / AA" mask="99 / 99" onChange={(e) => { updateUserForm('cardExpires', e.target.value); }} maskChar="" />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cvc">CVC</label>
                <InputMask className="form-control" placeholder="***" mask="999" onChange={(e) => { updateUserForm('cvc', e.target.value); }} maskChar="*" />
              </div>
            </div>
          </form>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => {
              setCardOwnerHolder(!cardOwnerHolder);
            }} />
            <label className="form-check-label" for="flexCheckDefault">
              O cartão de crédito é de outro titular que não sou eu
            </label>
          </div>
          {cardOwnerHolder && (
            <div style={{ paddingTop: '20px' }}>
              <h3>Informações do titular</h3>
              <form style={{ margin: '16px 0' }}>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputPassword1">CPF</label>
                    <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                      updateHolderForm('cpf', e.target.value)
                    }} maskChar="" />
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hnamee">Nome                     completo</label>
                    <input type="text" placeholder="Nome completo" className="form-control" id="hnamee" onChange={(e) => { updateHolderForm('name', e.target.value) }} />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputCel">Celular / Whatsapp</label>
                    {country === 'Brazil' ? (
                      <IconInput>
                        <i className="bi bi-telephone"></i>
                        <InputMask className="form-control" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => { updateHolderForm('mobilePhone', e.target.value); }} maskChar="" />
                      </IconInput>
                    ) : (
                      <IconInput>
                        <i className="bi bi-telephone"></i>
                        <input type="tel" className="form-control" placeholder="Número de telefone" onChange={(e) => { updateHolderForm('mobilePhone', e.target.value) }} />
                      </IconInput>
                    )}
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hexampleInputEmail1">Email</label>
                    <IconInput>
                      <i className="bi bi-envelope"></i>
                      <input type="email" placeholder="eu@email.com" className="form-control" id="hexampleInputEmail1" onChange={(e) => { updateHolderForm('email', e.target.value) }} />
                    </IconInput>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="exampleInputPassword1">{country === 'Brazil' ? 'CEP' : 'CEP'}</label>
                    <IconInput>
                      <i className="bi bi-geo-fill"></i>
                      <InputMask
                        className="form-control"
                        mask={country === 'Brazil' ? "99999-999" : "99999999"}
                        placeholder={country === 'Brazil' ? "00000-000" : "00000000"}
                        value={holderCep}
                        onChange={async (e) => {
                          const holderCepValue = e.target.value;
                          setHolderCep(holderCepValue);
                          if (country === 'Brazil' && holderCepValue.length === 9) {
                            let holderAddressInfo = await ConsultaCep(holderCepValue.replaceAll("-", ""));
                            if (!holderAddressInfo.cep) {
                              holderAddressInfo = await ConsultaCepBiz(holderCepValue.replaceAll("-", ""), holderAddressInfo);
                            }
                            setHolderAddress(holderAddressInfo);
                          }
                          else {
                            setHolderAddress(prev => ({ ...prev, cep: e.target.value }))
                          }
                        }}
                        maskChar=""
                      />
                    </IconInput>
                  </div>
                  <div className="form-group col-md-8">
                    <AddressBox style={{ margin: '25px' }}>
                      {country === 'Brazil' ? (
                        <>
                          <p>{holderAddress.street}</p>
                          <p>{holderAddress.neighborhood} - {holderAddress.city} - {holderAddress.state}</p>
                        </>
                      ) : (
                        <div className="row">
                          <div className="form-group col-md-4">
                            <input type="text" placeholder="Logradouro" className="form-control mb-2" onChange={(e) => {setHolderAddress(prev => ({ ...prev, street: e.target.value })); }} />
                          </div>
                          <div className="form-group col-md-2">
                            <input type="text" placeholder="Nº" className="form-control mb-2" onChange={(e) => updateHolderForm('addressNumber', e.target.value)} />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="text" placeholder="Complemento" className="form-control mb-2" onChange={(e) => updateHolderForm('complement', e.target.value)} />
                          </div>
                          <div className="form-group col-md-4">
                            <input type="text" placeholder="Bairro" className="form-control mb-2" onChange={(e) => {setHolderAddress(prev => ({ ...prev, neighborhood: e.target.value })); }} />
                          </div>
                          <div className="form-group col-md-4">
                            <input type="text" placeholder="Cidade" className="form-control mb-2" onChange={(e) => {setHolderAddress(prev => ({ ...prev, city: e.target.value })); }} />
                          </div>
                          <div className="form-group col-md-4">
                            <input type="text" placeholder="Estado" className="form-control mb-2" onChange={(e) => {setHolderAddress(prev => ({ ...prev, state: e.target.value })); }} />
                          </div>
                        </div>
                      )}
                    </AddressBox>
                  </div>
                </div>
                {country === 'Brazil' && (
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Número</label>
                      <input type="text" placeholder="000" className="form-control" onChange={(e) => updateHolderForm('addressNumber', e.target.value)} />
                    </div>
                    <div className="form-group col-md-8">
                      <label>Complemento</label>
                      <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" onChange={(e) => updateHolderForm('complement', e.target.value)} />
                    </div>
                  </div>
                )}
              </form>
            </div>
          )}
        </FormBox>
        <Divider></Divider>
        <div style={{ padding: '16px' }} className="d-grid gap-2">
          <button type="submit" disabled={disableSubmit} className="btn btn-success" onClick={async () => {
            let price = selectedPlan.price;

            if (plan === 'Custom') {
              if (customValue === 0 || customValue.length < 6) {
                setShowPriceError(true);
                return;
              }

              let thousands_seps = '.';
              let decimal_sep = ',';

              let sanitizeValue = customValue.replace(thousands_seps, '').replaceAll(decimal_sep, '.').replace(/[^0-9.-]+/, '');

              price = parseFloat(sanitizeValue);

              if (price < 99.9) {
                setShowPriceError(true);
                return;
              }
            }

            setIsLoading(true)

            const expires = userForm.cardExpires.split(' / ')

            const creditCardHolderInfo = cardOwnerHolder ? {
              name: holderForm.name,
              email: holderForm.email,
              cpfCnpj: holderForm.cpf,
              postalCode: holderAddress.cep,
              addressNumber: holderForm.addressNumber,
              addressComplement: holderForm.complement,
              phone: holderForm.mobilePhone
            } : {
              name: userForm.name,
              email: userForm.email,
              cpfCnpj: userForm.cpf,
              postalCode: address.cep,
              addressNumber: userForm.addressNumber,
              addressComplement: userForm.complement,
              phone: userForm.mobilePhone
            }

            const resp = await createUser({
              user: {
                name: userForm.name,
                cpfCnpj: userForm.cpf,
                email: userForm.email,
                mobilePhone: userForm.mobilePhone,
                address: address.street,
                addressNumber: userForm.addressNumber,
                complement: userForm.complement,
                province: address.neighborhood,
                postalCode: address.cep,
                notificationDisabled: true,
                observations: (userForm.shirt ? `camiseta:${userForm.shirt}` : "")
              },
              payment: {
                billingType: 'CREDIT_CARD',
                value: price,
                nextDueDate: Moment().format("YYYY-MM-DD"),
                cycle: 'MONTHLY',
                description: selectedPlan.asaasDescription,
                creditCard: {
                  holderName: userForm.cardName,
                  number: userForm.creditCard.number.replaceAll(' ', ''),
                  expiryMonth: expires[0],
                  expiryYear: `20${expires[1]}`,
                  ccv: userForm.cvc,
                },
                creditCardHolderInfo,
                remoteIp: ip,
              }
            })

            if (resp.success) {
              setIsLoading();
              setShowSuccess(true);
            } else {
              setIsLoading();
              setShowError(true);
              setTimeout(() => {
                setShowError();
              }, 5000)
            }
          }}>Confirmar minha associação...</button>
        </div>
      </div>
      <LoadingWrapper className={`${!isloading && 'd-none'}`}>
        <LoadingBox>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#47CD89', '#47CD89', '#47CD89', '#47CD89', '#47CD89']}
          />
          <p>Processando sua associação...</p>
        </LoadingBox>
      </LoadingWrapper>
      <SuccessWrapper className={`${!showSuccess && 'd-none'}`}>
        <SuccessBox>
          <h2>Muito obrigado!!!</h2>
          <p>Sua associação nos ajudará a construir um mundo melhor!</p>
        </SuccessBox>
      </SuccessWrapper>
    </div>
  );
}

export default Checkout;