export default async  (CEP, address) => {
  if (!address.cep){
    let response = await fetch(`https://apinacaoreal.simplesnologia.biz/api/varejo/vw_cep_v2?cep=${CEP}`);
    let newAddress = await response.json();

    if (newAddress.Erros.length == 0) {
      newAddress = JSON.parse(newAddress.ResultadoObjeto);

      if (newAddress.length > 0) {
        address = newAddress[0];
      }
    }

  }

  return address;
}