import React, { useState } from 'react';
import {
  Wrapper, Header, PriceTierCards, PriceTierCard, Badge,
  PriceTierHead, PriceTierPriceBlock, PriceTierPrice,
  BuyButtom, Benefits, BenefitsTitle, BenefitsWelcome,
  BenefitsList, BenefitsListInvertido, FAQBox, FAQAccordion, AccordionItem,
  FAQTitle, FAQText, BoxSpacer, FaqDivider,
  CustomWrapper, CustomTitle, CustomButton, CustomButtonInvertido
} from '../Atoms';
import { AnalyticEvent } from '../Components/Analytics';

function Landing({ onOpenCheckout }) {
  const [accordionItems, setAccordionItems] = useState({
    AC1: false,
    AC2: false,
    AC3: false,
  });

  const toogleHeight = (item) => {
    const newACs = { ...accordionItems };

    if (newACs[item]) {
      newACs[item] = false;
    } else {
      newACs[item] = true;
    }

    setAccordionItems(newACs);
  }
  return (<>
    <Wrapper>
      <Header className="App-header">
        <h1>Um lugar que transborda amor e movimento, inspirado pela luz de Dona Mara</h1>
        <p>Associe-se e faça parte, contribuindo para projetos e ações capazes de transformar vidas.</p>
      </Header>
      <PriceTierCards>
        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Associado <b>Lavrador</b></p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice>R$ 14,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso plano de entrada.</p>
            <BuyButtom onClick={() => {
              AnalyticEvent('Lavrador')
              onOpenCheckout('Lavrador')
            }}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check1">
              <li>Rodas de conversa promovidas pelo Instituto Dona Mara</li>
              <li>1 caneta exclusiva do Instituto Dona Mara</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Associado <b>Semeador</b></p>
              <Badge>Mais Popular</Badge>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice>R$ 49,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso plano mais popular.</p>
            <BuyButtom onClick={() => {
              AnalyticEvent('Semeador')
              onOpenCheckout('Semeador')
            }}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check2">
              <li>Rodas de conversa promovidas pelo Instituto Dona Mara</li>
              <li>1 caneta exclusiva do Instituto Dona Mara</li>
              <li>1 caneca exclusiva do Instituto Dona Mara</li>
              <li><b>1 ingresso</b> anual para as oficinas de aprendizado promovidas pelo Instituto Dona Mara</li>
              <li><b>1 ingresso</b> anual para as palestras promovidas pelo Instituto Dona Mara</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Associado <b>Cultivador</b></p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice>R$ 99,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso plano mais potente.</p>
            <BuyButtom onClick={() => {
              AnalyticEvent('Cultivador')
              onOpenCheckout('Cultivador')
            }}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check3">
              <li>Rodas de conversa promovidas pelo Instituto Dona Mara</li>
              <li>1 caneta exclusiva do Instituto Dona Mara</li>
              <li>1 caneca exclusiva do Instituto Dona Mara</li>
              <li>1 camiseta exclusiva do Instituto Dona Mara</li>
              <li><b>2 ingressos</b> anuais para as oficinas de aprendizado promovidas pelo Instituto Dona Mara</li>
              <li><b>2 ingressos</b> anuais para as palestras promovidas pelo Instituto Dona Mara</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>
      </PriceTierCards>
    </Wrapper>
    <CustomWrapper>
      <div>
        <BoxSpacer>
          <CustomTitle>Seja um associado INSPIRADOR e vamos AMARa em movimento juntos!</CustomTitle>
          <CustomButtonInvertido onClick={() => {
            AnalyticEvent('Custom')
            onOpenCheckout('Custom')
          }}>
            <i className="bi bi-stars"></i>
            Quero ser inspirador...
          </CustomButtonInvertido>
        </BoxSpacer>
        <Benefits>
          <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
          <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
          <BenefitsListInvertido checkType="check3">
            <li>Rodas de conversa promovidas pelo Instituto Dona Mara</li>
            <li>1 caneta exclusiva do Instituto Dona Mara</li>
            <li>1 caneca (sustentável) exclusiva do Instituto Dona Mara</li>
            <li>1 camisa pólo exclusiva do Instituto Dona Mara</li>
            <li><b>3 ingressos</b> anuais para as oficinas de aprendizado promovidas pelo Instituto Dona Mara</li>
            <li><b>3 ingressos</b> anuais para as palestras promovidas pelo Instituto Dona Mara</li>
            <li>Descontos exclusivos na feira de vinhos da Padaria Real</li>
          </BenefitsListInvertido>
        </Benefits>
      </div>
    </CustomWrapper>
    <FaqDivider><span>Tem dúvidas?</span></FaqDivider>
    <Wrapper>
      <FAQBox>
        <h2>Perguntas Frequentes</h2>
        <FAQAccordion>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC1')}>
              A minha associação ajudará em quê?
              <i className={`bi bi-chevron-${accordionItems.AC1 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC1 ? '' : 'd-none'}>
              Sua associação nos ajudará a manter o nosso Instituto e contribuir a formar jovens cidadãos para o mundo
            </FAQText>
          </AccordionItem>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC2')}>
              Minha associação irá consumir o limite do meu cartão de crédito?
              <i className={`bi bi-chevron-${accordionItems.AC2 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC2 ? '' : 'd-none'}>
              Pode ficar tranquilo: a sua associação não irá consumir o limite do seu cartão de crédito. O débito de sua associação será feita mensalmente sem comprometer seu limite.
            </FAQText>
          </AccordionItem>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC3')}>
              Eu posso cancelar minha associação a qualquer momento?
              <i className={`bi bi-chevron-${accordionItems.AC3 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC3 ? '' : 'd-none'}>
              Pode sim. Para isso é necessário entrar em contato com o e-mail financeiro@institutodonamara.org.br.
            </FAQText>
          </AccordionItem>
        </FAQAccordion>
      </FAQBox>
    </Wrapper>
  </>);
}

export default Landing;
