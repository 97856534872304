import styled from 'styled-components'
import check from './Assets/check.png';
import check2 from './Assets/check-circle-2.png';
import check3 from './Assets/check-circle-3.png';

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding-top: 20px;
`;

export const Header = styled.div`
  padding: 0 15%;
  h1 {
    font-size: 48px;
  }
  p {
    padding: 20px 15%;
    font-size: 20px;
  }
  @media(max-width: 800px) {
    padding: 0 3%;
    h1 {
      font-size: 36px;
    }
    p {
      padding: 20px 0;
      font-size: 18px;
    }
  }
`;

export const PriceTierCards = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-start;
  padding-bottom: 40px;

  @media(max-width: 800px) {
    display: block;
    padding: 0 12px;
    padding-bottom: 12px;
  }
`;

export const PriceTierCard = styled.div`
  border: 1px solid #EAECF0;
  border-radius: 5px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  flex: 1;

  @media(max-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const BoxSpacer = styled.div`
  border-bottom: 1px solid #EAECF0;
  padding: 32px;
  text-align: left;
`;

export const PriceTierHead = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
`;

export const Badge = styled.span`
  background: #FDEAD6;
  border: 1px solid #FDE2C6;
  border-radius: 20px;
  color: #F79633;
  font-size: 14px;
  margin: 0;
  padding: 2px 6px;
`;

export const PriceTierPriceBlock = styled.div`
  display: flex;
  align-items: baseline;
`;

export const PriceTierPrice = styled.div`
  font-size: 60px;
  font-weight: 600;
  padding-right: 10px;
  color: ${props => props.inputColor || "#993233"};

  @media(max-width: 800px) {
    font-size: 54px;
  }
`;

export const BuyButtom = styled.div`
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  padding: 20px 0;
  color: #FFF;
  background: ${props => props.inputColor || "#993233"};

  &:hover {
    opacity: 0.8;
  }
`;

export const Benefits = styled.div`
  padding: 32px;
  text-align: left;
`;

export const BenefitsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const BenefitsWelcome = styled.div`
`;

export const BenefitsList = styled.ul`
  list-style:none;
  padding: 0;

  li {
    background:url(${check}) no-repeat 0 0;
    padding-left: 34px;
    margin-top: 16px;
    font-size: 16px;
  }
`;

export const BenefitsListInvertido = styled.ul`
  list-style:none;
  padding: 0;

  li {
    background:url(${check2}) no-repeat 0 0;
    padding-left: 34px;
    margin-top: 16px;
    font-size: 16px;
  }
`;

export const FaqDivider = styled.div`
  border-bottom: 1px solid #EAECF0;
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;

  span {
    position: absolute;
    top: -14px;
    left: 47%;
    background: #fff;
    padding: 0 10px;

    @media(max-width: 800px) {
      left: 36%;
    }
  }
`;

export const FAQBox = styled.div`
  padding-bottom: 24px;
`;

export const FAQAccordion = styled.div`
  text-align: left;
  max-width: 770px;
  margin: 0 auto;
  
  @media(max-width: 800px) {
    padding: 0 10px;
  }
`;

export const AccordionItem = styled.div`
  border-top: 1px solid #EAECF0;
  padding-top: 24px;
  margin-top: 32px;

 
`;

export const FAQTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const FAQText = styled.div`
  padding-top: 18px;
  font-size: 16px;
`;

export const Footer = styled.div`
  background: #EAECF0;
  padding: 10px 0;
  margin-top: 30px;

  img {
    width: 180px;
    height: 25px;
  }
`;

export const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: #BF4F74;
`;

export const CustomWrapper = styled.div`
  background: #993233;
  color: #FFF;
  padding: 60px 0;

  > div {
    margin: 0 auto;
    max-width: 740px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CustomTitle = styled.h3`
  font-size: 36px;
  text-align: center;
`;

export const CustomButton = styled.div`
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  padding: 20px 40px;
  color: #FFF;
  background: #993233;

  i {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const CustomButtonInvertido = styled.div`
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  padding: 20px 40px;
  color: #FFF;
  background: #E29D3F;

  i {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
